import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../components/questionnaire")
  },
  {
    name: "receipt",
    path: "/receipt",
    component: () => import("../components/receipt")
  },
  {
    path: "/receipt-info",
    component: () => import("../components/receipt-info")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
