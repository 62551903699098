import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import cookie from "vue-cookies";
import axios from "axios";

Vue.config.productionTip = false;
Vue.prototype.$cookie = cookie;

new Vue({
  router,
  store,
  cookie,
  render: (h) => h(App)
}).$mount("#app");

axios.defaults.timeout = 10000;
