<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    _isMobile() {
      console.log(
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      );
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    }
  },
  mounted() {
    this.isMobile = this._isMobile();
  }
};
</script>
<style>
.back-background {
  background-color: rgb(232, 240, 246);
}
</style>
